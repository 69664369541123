import React from 'react';
import styled from 'styled-components';
import { COLOR } from 'constants/COLOR';
import { RADIUS } from 'constants/RADIUS';

export const ButtonVariant = Object.freeze({
  Normal: 'normal',
  Outlined: 'outlined',
  DarkGrayOutlined: 'darkGrayOutlined',
  GrayOutlined: 'grayOutlined',
  Gray: 'gray',
  Black: 'black',
  Disabled: 'disabled',
  Text: 'text',
} as const);

type ButtonVariantProperty =
  | 'normal'
  | 'outlined'
  | 'darkGrayOutlined'
  | 'grayOutlined'
  | 'gray'
  | 'black'
  | 'disabled'
  | 'text';

export const ButtonTheme = {
  [ButtonVariant.Normal]: {
    normal: `
      color: ${COLOR.SURFACE};
      background: ${COLOR.PRIMARY};
      font-weight: 600;
    `,
    disabled: `
      color: ${COLOR.DISABLED} !important;
      background: ${COLOR.ON_DISABLED} !important;
    `,
  },
  [ButtonVariant.Outlined]: {
    normal: `
      border: 1px solid ${COLOR.PRIMARY};
      color: ${COLOR.PRIMARY};
      font-weight: 600;
    `,
    disabled: `
      color: ${COLOR.DISABLED} !important;
      border: ${COLOR.DISABLED};
      background: ${COLOR.SURFACE} !important;
    `,
  },
  [ButtonVariant.DarkGrayOutlined]: {
    normal: `
      border: 1px solid ${COLOR.DARK_GRAY};
      color: ${COLOR.DARK_GRAY};
    `,
    disabled: `
      color: ${COLOR.DISABLED} !important;
      background: ${COLOR.ON_DISABLED} !important;
    `,
  },
  [ButtonVariant.GrayOutlined]: {
    normal: `
      border: 1px solid ${COLOR.GRAY};
      color: ${COLOR.GRAY};
      font-weight: 500;
    `,
    disabled: `
      color: ${COLOR.DISABLED} !important;
      background: ${COLOR.ON_DISABLED} !important;
    `,
  },
  [ButtonVariant.Gray]: {
    normal: `
      color: ${COLOR.GRAY};
      background: rgba(23,40,73,0.08);
      font-weight: 500;
    `,
    disabled: `
      color: ${COLOR.DISABLED} !important;
      background: ${COLOR.ON_DISABLED} !important;
    `,
  },
  [ButtonVariant.Black]: {
    normal: `
      color: ${COLOR.SURFACE};
      background: ${COLOR.BLACK};
      font-weight: 500;
    `,
    disabled: `
      color: ${COLOR.DISABLED} !important;
      background: ${COLOR.ON_DISABLED} !important;
    `,
  },
  [ButtonVariant.Disabled]: {
    normal: `
      color: ${COLOR.DISABLED} !important;
      background: ${COLOR.ON_DISABLED} !important;
      font-weight: 500;
    `,
    disabled: `
      color: ${COLOR.DISABLED} !important;
      background: ${COLOR.ON_DISABLED} !important;
    `,
  },
  [ButtonVariant.Text]: {
    normal: `
      color: ${COLOR.DISABLED};
      background: ${COLOR.SURFACE};
      font-weight: 600;
    `,
    disabled: `
      color: ${COLOR.DISABLED};
      background: ${COLOR.ON_DISABLED}
    `,
  },
};

type ButtonProps = {
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  borderRadius?: number;
  borderRadiusTB?: number;
  borderRadiusMB?: number;
  disabled?: boolean;
  fullWidth?: boolean;
  fontSize?: number;
  fontSizeTB?: number;
  fontSizeMB?: number;
  width?: number;
  height?: number;
  variant?: ButtonVariantProperty;
  marginRight?: number;
  className?: string;
  type?: 'submit' | 'button';
  children?: React.ReactNode;
};

const Component = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 22px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  font-size: ${({ fontSize }) => fontSize || 16}px;

  ${({ variant, disabled }) => {
    const theme = ButtonTheme[variant || ButtonVariant.Normal] || ButtonTheme[ButtonVariant.Normal];
    return `
      ${theme.normal}
      ${disabled ? `pointer-events: none; ${theme.disabled}` : ''}
    `;
  }}
  ${({ width }) => (width ? `width: ${width}px; padding: 4px 8px;` : '')}
  ${({ height = 40 }) => `height: ${height}px;`}
  ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
  font-weight: ${({ width }) => width && width < 100 && 400};
  border-radius: ${({ borderRadius }) => borderRadius || RADIUS.PC}px;

  ${({ theme, borderRadiusTB, fontSizeTB }) =>
    theme.media.M(`
    border-radius: ${borderRadiusTB || RADIUS.TB}px;
    font-size: ${fontSizeTB || 14}px;
  `)}

  ${({ theme, width = 80, height = 32, fontSizeMB, borderRadiusMB, fullWidth }) =>
    theme.media.S(`
    width: ${fullWidth ? '100%' : `${width}px`};
    height: ${fullWidth ? '40px' : `${height}px`};
    font-size: ${fontSizeMB || 14}px;
    border-radius: ${borderRadiusMB || RADIUS.MB}px;
    padding:0
  `)}
`;

// eslint-disable-next-line react/function-component-definition
export const Button: React.FC<ButtonProps> = ({
  onClick,
  borderRadius,
  borderRadiusTB,
  borderRadiusMB,
  disabled,
  fullWidth,
  width,
  height,
  fontSize,
  fontSizeTB,
  fontSizeMB,
  marginRight,
  variant = ButtonVariant.Normal,
  className,
  type,
  children,
  ...props
}) => {
  return (
    <Component
      onClick={onClick}
      borderRadius={borderRadius}
      borderRadiusTB={borderRadiusTB}
      borderRadiusMB={borderRadiusMB}
      disabled={disabled}
      fontSize={fontSize}
      fontSizeTB={fontSizeTB}
      fontSizeMB={fontSizeMB}
      fullWidth={fullWidth}
      width={width}
      height={height}
      variant={variant}
      marginRight={marginRight}
      className={className}
      type={type || 'button'}
      {...props}
    >
      {children}
    </Component>
  );
};
