import { Dispatch, useState, useEffect, SetStateAction } from 'react';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import langAtom from 'stores/langAtom';
import { FONT_SIZE } from 'constants/FONT_SIZE';
import { useRouter } from 'next/router';
import { RADIUS } from 'constants/RADIUS';
import { COLOR } from 'constants/COLOR';
import { nestTernary } from 'utils/nestTernary';
import { useTranslation } from 'react-i18next';
import { getInAppBrowserType, InAppBrowserType } from 'utils/getInAppBrowserType';

export type ButtonType = 'Kakao' | 'Google' | 'Whale' | 'Naver';

type ButtonProps = {
  type?: ButtonType;
  marginBottom?: number;
  marginBottomMb?: number;
  isSignUp?: boolean;
  customLabel?: string;
  setIsShowInAppBrowserNotice: Dispatch<SetStateAction<boolean>>;
  isLandingPage?: boolean;
};

const Component = styled.button<{
  marginBottom?: number;
  marginBottomMb?: number;
  isLandingPage?: boolean;
  bgColor: string;
  }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: ${({ isLandingPage }) => (isLandingPage ? '224px' : '100%')};
  height: 48px;
  background: ${({ bgColor }) => bgColor || COLOR.SURFACE};
  border: ${({ isLandingPage, bgColor }) => (
    bgColor !== COLOR.SURFACE
      ? 'none'
      : isLandingPage
        ? `1px solid ${COLOR.GRAY}`
        : `1px solid ${COLOR.OUTLINE}`)};
  border-radius: ${RADIUS.PC}px;

  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

  ${({ theme, isLandingPage }) =>
    theme.media.M(`
      ${
  isLandingPage
    ? `width: 200px; height: 40px; border-radius: ${RADIUS.TB}px; border: 1px solid ${COLOR.OUTLINE};`
    : ''
}
  `)}

  ${({ theme, marginBottomMb, isLandingPage }) =>
    theme.media.S(`
      ${isLandingPage ? `width: 100%; border: 1px solid ${COLOR.OUTLINE};` : ''}
      height: 40px;
      border-radius: ${RADIUS.MB}px;
      ${marginBottomMb && `margin-bottom: ${marginBottomMb}px;`}
  `)}

  span {
    margin-left: 8px;
    font-weight: 500;
    font-size: ${FONT_SIZE.bodyM}px;
    color: ${({ bgColor }) => bgColor === COLOR.NAVER ? COLOR.SURFACE : COLOR.BLACK};

    ${({ theme, isLandingPage }) =>
    theme.media.M(`
      ${isLandingPage ? `font-size: ${FONT_SIZE.bodyS}px;` : ''}
  `)}

    ${({ theme }) =>
    theme.media.S(`
        font-size: ${FONT_SIZE.bodyS}px;
        margin-left: 4px;
    `)}
  }
`;

const ImgContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export function SocialLoginButton({
  type,
  marginBottom,
  marginBottomMb,
  isSignUp,
  customLabel,
  setIsShowInAppBrowserNotice,
  isLandingPage,
}: ButtonProps) {
  /* ============================== 0. Init ============================== */
  // --------------------------------------------------------------------------
  // State
  // --------------------------------------------------------------------------
  const [inAppBrowserType, setInAppBrowserType] = useState<InAppBrowserType>(undefined);

  const [lang] = useAtom(langAtom);
  // --------------------------------------------------------------------------
  // Etc
  // --------------------------------------------------------------------------
  const { t } = useTranslation('auth');
  const router = useRouter();
  const { pathname, query } = router;
  const { ref } = query;
  const stateRef = ref ? nestTernary(ref === pathname, '', ref.toString()) : '';
  const state = `${isSignUp ? 'signup' : 'signin'}${stateRef}`;

  const buttonType: {
    Kakao: {src: string; width: number; height: number};
    Google: {src: string; width: number; height: number};
    Whale: {src: string; width: number; height: number};
    Naver: {src: string; width: number; height: number};
  } = {
    Kakao: {
      src: '/images/kakao.svg',
      width: 18,
      height: 16.5,
    },
    Google: {
      src: '/images/google.png',
      width: 20,
      height: 20,
    },
    Whale: {
      src: '/images/whale.png',
      width: 24,
      height: 24,
    },
    Naver: {
      src: '/images/naver.svg',
      width: 14.52,
      height: 14.4,
    },
  };
  const selected = type ? buttonType[type] : buttonType.Kakao;

  let label = '';
  switch (type) {
    case 'Google':
      label = t('SocialLoginButton.google');
      break;
    case 'Kakao':
      label = t('SocialLoginButton.kakao');
      break;
    case 'Whale':
      label = t('SocialLoginButton.whale');
      break;
    case 'Naver':
      label = t('SocialLoginButton.naver');
      break;
    default:
      label = t('SocialLoginButton.google');
      break;
  }

  /* ======================================================================== */
  /* ============================== 1. Functions ============================== */

  const handleOnClick = () => {
    if (type === 'Google' && typeof inAppBrowserType === 'undefined') {
      // Google case0
      if (isSignUp) {
        router.push({ pathname: '/auth/google/sign-up', query: { init: 'true', ref } });
      } else {
        router.push({ pathname: '/auth/google/sign-in', query: { init: 'true', ref } });
      }
    } else if (type === 'Google' && typeof inAppBrowserType === 'string') {
      // Google case 1
      if (inAppBrowserType === 'kakao' || inAppBrowserType === 'naver') {
        // show in app browser notice component when user is trying to login with Google OAuth on Naver, Kakao in app browser
        setIsShowInAppBrowserNotice(true);
      } else {
        // show alert when user is trying to login with Google OAuth on Facebook, Naver Band in app browser
        alert(t('alert.inAppBrowserAlert'));
      }
    } else if (type === 'Kakao') {
      // Kakao login
      // todo. update state token for more security
      window.Kakao.Auth.authorize({
        redirectUri: `${process.env.BASE_URL}/auth/kakao`,
        state,
      });
    } else if (type === 'Whale') {
      // Whale login
      // todo. update state token for more security
      const whaleAuthUrl = `https://auth.whalespace.io/oauth2/v1.1/authorize?response_type=code&state=${state}&redirect_uri=${process.env.BASE_URL}/auth/whale&client_id=${process.env.WHALE_CLIENT_ID}`;
      router.push(encodeURI(whaleAuthUrl));
    } else if (type === 'Naver') {
      // Naver login
      const naverBaseUrl = 'https://nid.naver.com/oauth2.0/authorize';
      // todo. update state token for more security
      const naverAuthUrl = `${naverBaseUrl}?response_type=code&client_id=${process.env.NAVER_CLIENT_ID}&redirect_uri=${process.env.BASE_URL}/auth/naver&state=${state}`;
      router.push(encodeURI(naverAuthUrl));
    }
  };

  useEffect(() => {
    const tmpInAppBrowserType = getInAppBrowserType(navigator.userAgent);
    setInAppBrowserType(tmpInAppBrowserType);
  }, []);

  let bgColor;
  if (lang === 'ko' && type === 'Kakao') {
    bgColor = COLOR.KAKAO;
  } else if (lang === 'ko' && type === 'Naver') {
    bgColor = COLOR.NAVER;
  } else {
    bgColor = COLOR.SURFACE;
  }

  return (
    <Component
      type="button"
      onClick={handleOnClick}
      marginBottom={marginBottom}
      marginBottomMb={marginBottomMb}
      isLandingPage={isLandingPage}
      bgColor={bgColor}
      >
      <ImgContainer>
        <Img src={selected.src} width={selected.width} height={selected.height} draggable={false} />
      </ImgContainer>
      {customLabel ? <span>{customLabel}</span> : <span>{label}</span>}
    </Component>
  );
}
