import Link from 'next/link';

type LogoProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  href: string;
}

export const Logo = ({ href, className, ...props }: LogoProps) => {
  return (
    <Link href={href}>
      <img
        src="/images/logo.svg"
        alt="engram logo"
        draggable={false}
        className={`h-auto ${className}`}
        {...props}
      />
    </Link>
  );
};
