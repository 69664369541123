import styled from 'styled-components';
import SVG from 'react-inlinesvg';

export type IconProps = {
  size: number;
  src: string;
  id?: string;
  alt?: string;
  className?: string;
  title?: string;
  inlineSvg?: boolean;
  marginLeft?: number;
  marginRight?: number;
  mbSize?: number;
  tbSize?: number;
  verticalAlign?: string;
};

type styleProps = {
  marginLeft?: number;
  marginRight?: number;
  mbSize?: number;
  tbSize?: number;
  verticalAlign?: string;
};

const Image = styled.img<styleProps>`
  ${({ verticalAlign }) => verticalAlign && `vertical-align : ${verticalAlign}; display: inline-block;`}
  flex-shrink: 0;
  object-fit: contain;
  ${({ marginLeft, marginRight }) => `
    ${marginLeft ? `margin-left: ${marginLeft}px;` : ''}
    ${marginRight ? `margin-right: ${marginRight}px;` : ''}
  `}

  ${({ theme, tbSize }) =>
    theme.media.M(`
    ${tbSize ? `width: ${tbSize}px; height: ${tbSize}px;` : ''}
  `)}

  ${({ theme, mbSize }) =>
    theme.media.S(`
    ${mbSize ? `width: ${mbSize}px; height: ${mbSize}px;` : ''}
  `)}
  ${({ theme, tbSize }) =>
    theme.media.M(`
  ${tbSize ? `width: ${tbSize}px; height: ${tbSize}px;` : ''}
`)}
`;
const SVGContainer = styled.div<styleProps>`
  display: inline-block;
  ${({ marginLeft, marginRight }) => `
    ${marginLeft ? `margin-left: ${marginLeft}px;` : ''}
    ${marginRight ? `margin-right: ${marginRight}px;` : ''}
  `}
  ${({ theme, tbSize }) =>
    theme.media.M(`
    ${
  tbSize
    ? `
    svg {
      width: ${tbSize}px; height: 'auto';
    }
    `
    : ''
}
  `)}
  ${({ theme, mbSize }) =>
    theme.media.S(`
    ${
  mbSize
    ? `
    svg {
      width: ${mbSize}px; height: 'auto';
    }
    `
    : ''
}
  `)}
`;

export const Icon = ({
  size,
  src,
  title,
  inlineSvg,
  marginLeft,
  marginRight,
  mbSize,
  tbSize,
  verticalAlign,
  alt = '',
  ...props
}: IconProps) => {
  return (
    <>
      {inlineSvg ? (
        <SVGContainer marginLeft={marginLeft} marginRight={marginRight} mbSize={mbSize} tbSize={tbSize}>
          <SVG src={src} width={size}>
            <Image
              alt={alt}
              title={title}
              width={size}
              height={size}
              src={src}
              draggable={false}
              style={{
                marginLeft,
                marginRight,
              }}
              {...props}
            />
          </SVG>
        </SVGContainer>
      ) : (
        <Image
          alt={alt}
          title={title}
          width={size}
          height={size}
          marginLeft={marginLeft}
          marginRight={marginRight}
          mbSize={mbSize}
          tbSize={tbSize}
          verticalAlign={verticalAlign}
          src={src}
          draggable={false}
          style={{
            marginLeft,
            marginRight,
          }}
          {...props}
        />
      )}
    </>
  );
};
