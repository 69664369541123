import React from 'react';
import styled from 'styled-components';

import { Icon, IconProps } from 'components/images';

export type IconButtonProps = {
  onClick: (() => void) | ((event: any) => void);
  onMouseOver?: (() => void) | ((event: any) => void);
  onMouseOut?: (() => void) | ((event: any) => void);
  buttonSize?: number;
  className?: string;
};

type styleProps = {
  size: number;

  buttonSize?: number;
  marginLeft?: number;
  marginRight?: number;
  tbSize?: number;
  mbSize?: number;
};

const Component = styled.button<styleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${({ buttonSize, size }) => `
    width: ${buttonSize || size}px;
    height: ${buttonSize || size}px;
  `}
  ${({ marginLeft, marginRight }) => `
    ${marginLeft ? `margin-left: ${marginLeft}px;` : ''}
    ${marginRight ? `margin-right: ${marginRight}px;` : ''}
  `}
  ${({ theme, tbSize }) =>
    tbSize
      ? theme.media.M(`
  width: ${tbSize}px;
  height: ${tbSize}px;
`)
      : ''}
  ${({ theme, mbSize }) =>
    mbSize
      ? theme.media.S(`
    width: ${mbSize}px;
    height: ${mbSize}px;
  `)
      : ''}
`;

export const IconButton = ({
  alt,
  buttonSize,
  inlineSvg,
  tbSize,
  mbSize,
  marginLeft,
  marginRight,
  size,
  src,
  title,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseOut = () => {},
  ...props
}: IconButtonProps & IconProps) => {
  return (
    <Component
      mbSize={mbSize}
      buttonSize={buttonSize}
      marginLeft={marginLeft}
      marginRight={marginRight}
      size={size}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      {...props}
    >
      <Icon alt={alt} title={title} inlineSvg={inlineSvg} size={size} src={src} mbSize={mbSize} tbSize={tbSize} />
    </Component>
  );
};
