export type InAppBrowserType = undefined | 'kakao' | 'naver' | 'alert';

/**
 * Takes userAgent string,
 * and returns 'naver' or 'kakao' if user is accessing from
 * Kakao or Naver browser. returns 'alert' if user is accessing from
 * Naver Band, KakaoStory, Facebook inapp browser. Returns undefined if else.
 * @param userAgentString user agent string
 * @returns 'naver', 'kakao', 'alert' or undefined
 */
export const getInAppBrowserType = (userAgentString: string) => {
  if (!userAgentString) {
    return undefined;
  }

  if (/KAKAOTALK|NAVER\(inapp|BAND|FB/.test(userAgentString)) {
    if (userAgentString?.includes('KAKAOTALK')) {
      return 'kakao';
    } else if (userAgentString?.includes('NAVER(inapp')) {
      return 'naver';
    } else {
      return 'alert';
    }
  }

  return undefined;
};
